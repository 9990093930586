<template>
  <div>
    <div v-if="user && !hidePanel" class="login-id">
      <span class="avatar">
        <UserPhoto />
      </span>
      <span class="fullname">
        <v-clamp autoresize :max-lines="1">
          {{ user.name }}
        </v-clamp>
      </span>
      <span class="email">
        <v-clamp autoresize :max-lines="1">
          {{ user.email }}
        </v-clamp>
      </span>
      <a class="clear" @click="resetLocalUser">{{ $t('anotherAccount') }}</a>
    </div>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'
import store from '@/store'

import UserPhoto from '@/components/user/UserPhoto'

export default {
  name: 'LoginId',
  components: {
    VClamp,
    UserPhoto,
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: () => store.getters.getLocalUser,
    },
  },
  data() {
    return {
      hidePanel: false,
    }
  },
  methods: {
    resetLocalUser() {
      this.$emit('reset-local-user', null)
      this.hidePanel = true
    },
  },
}
</script>
