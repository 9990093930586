<template>
  <BasicLayout>
    <template v-slot:content>
      <div class="page-login">
        <LoginId :user.sync="localUser" @reset-local-user="resetLocalUser" />

        <form class="login-form" method="post" @keypress.enter="doLogin" @submit.prevent="doLogin">
          <transition name="fade">
            <b-field
              v-if="!localUser || localUser.length"
              label="E-mail"
              label-for="email"
              :type="{ 'is-danger': $v.email.$error }"
              :message="$v.email.$error ? $t('invalidEmail') : ''"
            >
              <b-input v-model="email" type="email"></b-input>
            </b-field>
          </transition>
          <b-field
            label="Password"
            label-for="password"
            :type="{ 'is-danger': $v.password.$error }"
            :message="$v.password.$error ? $t('login.setPassword') : ''"
          >
            <b-input v-model="password" type="password" name="password" />
          </b-field>
          <b-message v-if="loginErrors" type="is-danger">
            <div v-for="error in loginErrors" :key="error.key">
              <div v-if="error.key == 'MaxAttempts'">
                {{ $t('login.maxAttempts') }}
              </div>
              <div v-if="error.key == 'InvalidEmailOrPassword' || error.key == 'NoPasswordDefined'">
                {{ $t('login.invalidPassword') }}
                <router-link to="/login/recover">{{ $t('login.pwdForgot') }}</router-link>
              </div>
              <div v-else-if="error.key == 'UserNotRegistered'">
                {{ $t('login.emailNotRegistered') }}<br /><br /><small
                  >{{ $t('login.toSignin') }}
                  <a @click="$router.push('/register')">{{ $t('login.goRegisterPage') }}</a>
                  {{ $t('login.doRegister') }}</small
                >
              </div>
              <div v-else>
                {{ $t('login.authFailed') }}
              </div>
            </div>
          </b-message>
          <b-field>
            <b-button expanded type="is-primary" size="is-medium" @click="doLogin">{{ $t('login.open') }}</b-button>
          </b-field>
          <b-field>
            <b-checkbox v-model="keepSignIn" size="is-small">{{ $t('login.keepSession') }}</b-checkbox>
          </b-field>
          <p class="has-text-small">
            {{ $t('login.security') }}
          </p>
          <div class="buttons">
            <router-link to="/register" class="has-text-weight-bold">{{ $t('login.register') }}</router-link>
          </div>
        </form>
      </div>
    </template>
  </BasicLayout>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'

import BasicLayout from '@/components/layouts/BasicLayout'
import LoginId from '@/components/user/LoginId'

export default {
  name: 'Login',

  components: {
    LoginId,
    BasicLayout,
  },

  data() {
    return {
      email: '',
      password: '',
      keepSignIn: false,
      loginErrors: '',
      localUser: this.$store.getters.getLocalUser,
    }
  },

  validations: {
    email: { required, email, min: minLength(5) },
    password: { required, min: minLength(1) },
    keepSignIn: false,
  },

  created() {
    this.localUser = this.$store.getters.getLocalUser
    this.email = this.localUser ? this.localUser.email : this.email
  },

  methods: {
    resetLocalUser() {
      this.$store.dispatch('resetLocalUser')
      this.localUser = null
      this.email = ''
    },
    doLogin() {
      this.$v.$touch()

      // Realiza pedido de login apenas se o formulário for validado
      if (!this.$v.$invalid) {
        this.loginError = false

        // Utiliza a ação no store para executar o pedido à API
        this.$store
          .dispatch('login', {
            email: this.email,
            password: this.password,
            keepSignIn: this.keepSignIn,
          })
          .then(response => {
            this.$router.push('/')
            return response
          })
          .catch(err => {
            this.loginErrors = err.response.data.__errors__
          })
      }
    },
  },
}
</script>
